<template>
  <div>
    <div class="pc container-top">
      <div class="banner">
        <div class="container">
          <div class="circle"></div>
        </div>
        <div class="banner-content">
          <div style="text-align: center">
            <div>
              <div class="h2">주문 제작 이용 가이드</div>
              <div class="subtitle3 sub2" style="margin-top:12px">A to Z, 나만을 위한 VIP 솔루션</div>
            </div>
          </div>
        </div>
      </div>
      <div class="container">
        <div v-for="(item,idx) in content" :key="'content-'+idx">
          <div class="subtitle3 main">{{ item.title }}</div>
          <div v-if="item.content" class="body2 sub2">{{ item.content }}</div>
          <template v-else>
            <div v-for="(step,idx) in item.step" :key="'step-'+idx" class="box-step">
              <div class="h7 primary">STEP {{ idx+1 }}</div>
              <div class="h6 main step-title">{{ step.title }}</div>
              <div class="body2 sub2">{{ step.content }}</div>
            </div>
          </template>
        </div>
        <div class="flex-center" style="margin-top:80px">
          <button class="button is-primary" @click="clickLink">견적신청 바로가기</button>
        </div>
      </div>
    </div>
    <div class="mobile container-top">
      <div class="banner">
        <div class="container">
          <div class="circle"></div>
        </div>
        <div class="banner-content">
          <div style="text-align: center">
            <div>
              <div class="h6">주문 제작 이용 가이드</div>
              <div class="body4 sub2" style="margin-top:4px">A to Z, 나만을 위한 VIP 솔루션</div>
            </div>
          </div>
        </div>
      </div>
      <div class="container">
        <div v-for="(item,idx) in content" :key="'content-'+idx">
          <div class="subtitle5 main">{{ item.title }}</div>
          <div v-if="item.content" class="body4 sub2">{{ item.content }}</div>
          <div v-else>
            <div v-for="(step,idx) in item.step" :key="'step-'+idx" class="box-step">
              <div class="h8 primary">STEP {{ idx+1 }}</div>
              <div class="body4-bold main step-title">{{ step.title }}</div>
              <div class="body5 sub2">{{ step.content }}</div>
            </div>
          </div>
        </div>
        <div class="flex-center" style="margin-top:80px">
          <button class="button is-primary" @click="clickLink">견적신청 바로가기</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: "ServiceInquiryGuide",
    created() {

    },
    data() {
      return {
        content: [
          {
            title: '주문제작 서비스란?',
            content: '기획단계에서 배치된 담당자의 안내에 따라 클라이언트는 서비스 구조 설계와 uiux 디자인, ' +
              '개발로 진행되는 프로젝트 전과정에 참여하여 나만의 특별한 서비스를 제작할 수 있습니다.'
          },
          {
            title: '계약진행절차',
            content: "'서비스견적 문의하기 > 주문제작 견적신청' 페이지에서 신청서 작성 후 상세한 서비스 파악을 위해  담당자가 유선 상담을 진행합니다. " +
              "유선 상담에서 1차적으로 파악된 서비스의 스펙으로 상담일 기준 1~2일 후 신청자의 메일 계정으로 서비스 제작 견적서 및 런치팩 포트폴리오가 발송됩니다." +
              " 이후 진행절차는 클라이언트의 상황에 따라 차이가 있으며, 최종으로 서비스 스펙 정리가 되면 계약을 진행합니다.\n\n" +
              " 정부지원사업 대상자의 경우 진행하시는 사업 내용에 따라 사업비 지원일자에 차이가 있을 수 있습니다." +
              " 다만, 저희 런치팩은 선입금/후개발이 원칙이기에 계약서에 기록된 일정에 따라 계약금 부분 납부 확인 후 프로젝트가 진행된다는 점 양해부탁드립니다."
          },
          {
            title: '프로젝트 진행',
            step: [
              {
                title: '기획/디자인',
                content: "클라이언트의 기획안를 기반으로 서비스 구조 설계와 디자인 작업이 진행됩니다. 효율적인 프로젝트 진행을 위해 공통페이지(회원가입, 공지사항 등)는 런치팩이 보유한 템플릿을 응용하며, 메인페이지(홈, 상품관리,주문관리 등)는 클라이언트와 기획자,디자이너의 미팅을 통해 결정된 페이지 레이아웃을 기반으로 프론트 시안이 제작됩니다. \n" +
                  "\n" +
                  "제작된 시안을 클라이언트가 컨펌 후 개발 작업에 착수합니다. 이는 납기 후 투입될 수정 리소스를 최소화하기 위함이며 해당 과정에 전체일정의 30% 가량이  소요될 예정입니다. "
              },
              {
                title: '개발',
                content: "개발은 풀스택 개발자를 중심으로 프론트와 백엔드 작업자가 한팀이 되어, 선 제작된 런치팩의 플랫폼 솔루션들을 조합하거나 클라이언트가 원하는 기능을  솔루션에 추가하는 방식으로 진행됩니다. \n" +
                  "\n" +
                  "선 제작된 솔루션을 기반으로 작업을 진행하는 방식은 프로젝트 진행 일정을 축소시킬뿐 아니라, 클라이언트가 납기 전에도 틈틈히 진행 상황을 확인할 수 있어 납기 후 발생될 수 있는 제작사와 클라이언트 간 결과물 퀄리티에 대한 의견 차이를 최소화할 수 있습니다."
              }
            ]
          },
          {
            title: '런치팩 한마디',
            content: '저희 런치팩에서는 대표님들께 최초 런칭에서 풀스펙의 서비스를 구현하시는 것을 추천드리지 않습니다. ' +
              '우선 mvp 수준의 서비스를 선 런칭하여 아이디어에 대한 시장 반응을 확인한 후, 점차적으로 서비스를 고도화하시는 ' +
              '비즈니스 모델을 추천드립니다. 때문에 런치팩의 솔루션은 이후의 추가개발이 용이하게 진행될 수 있도록 백엔드 로직이' +
              ' 구성되었으며, 주문제작 계약 고객분들에게는 서비스관리 담당자를 매칭하여 플랫폼 서비스 운영에 필요한 여러 이슈들에 대해' +
              ' 유연하게 대처하실 수 있도록 지원하고 있습니다.'
          }
        ]
      }
    },
    methods: {
      clickLink() {
        this.$router.push('/service_inquiry_reg');
      }
    }
  }
</script>

<style lang="stylus" scoped>
  @import '~assets/css/lp_main'
  .banner
    background-color $gray4
    height 320px
    position relative
    overflow hidden
    color $main
  .banner-content
    position absolute
    width $page-width
    left 50%
    transform translateX(-50%)
    display flex
    align-items center
    justify-content center
    height 100%
    z-index 1
  .circle
    background-color #17FF90
    opacity 0.15
    filter blur(300px)
    -webkit-backdrop-filter blur(300px)
    backdrop-filter blur(300px)
    position absolute
    top -70px
    left -643px
    width $page-width
    height $page-width
  .subtitle3
    margin 80px 0 16px 0
  .subtitle5
    margin 32px 0 8px 0
  .body2
  .body4
  .body5
    white-space pre-line
  .box-step
    border 1px solid $gray1
    border-radius 8px
    padding 40px
    margin-top 32px
  .box-step:first-child
    margin-top 16px

  .step-title
    padding 8px 0 16px 0
    border-bottom 1px solid $gray2
    margin-bottom 16px
  .button
    width 242px

  @media (max-width:1024px)
    .banner
      height 160px
      padding 28px 0
    .banner-content
      width 100%
      top 0
      text-align center
    .circle
      width 370px
      height 370px
      top -50px
      left -50px
    .box-step
      padding 16px
    .step-title
      padding 8px 0
      margin-bottom 8px
    .button
      font-size 13px
      width 170px
      height 36px
</style>
